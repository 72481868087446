import React from "react"
import Layout from "../components/layout/Layout"
import Cta from "../components/sections/Cta"
import Experience from "../components/sections/Experience"
import Distributors from "../components/sections/Distributors"
import SideProjects from "../components/projects/SideProjects"
import Main from "../components/pageLayout/Main"
import Sidebar from "../components/pageLayout/Sidebar"
import Content from "../components/pageLayout/Content"
import Banner from "../components/header/Banner"
import SideShop from "../components/shop/SideShop"
import SEO from "../components/SEO"
import ShopContent from "../components/shop/ShopContent"
import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const shop = useStaticQuery(graphql`
    query {
      allContentfulProdejna {
        edges {
          node {
            nazev
            kratkyText
            hlavniFotka {
              fluid(maxWidth: 1400, quality: 50) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `)

  const {
    nazev,
    kratkyText,
    hlavniFotka,
  } = shop.allContentfulProdejna.edges[0].node

  return (
    <Layout>
      <SEO title="Kamenná prodejna" />
      <Banner
        text={kratkyText}
        heading={nazev}
        img={hlavniFotka.fluid}
        alt="Foto prodejny Agotop"
      />
      <Main>
        <Sidebar>
          <SideShop />
          <SideProjects />
        </Sidebar>
        <Content>
          <ShopContent />
        </Content>
      </Main>
      <Cta text="default" />
      <Experience />
      <Distributors />
    </Layout>
  )
}
