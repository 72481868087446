import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Map from "../map/Map"

const getContent = graphql`
  query {
    allContentfulProdejna {
      edges {
        node {
          nazev
          hlavniPopis {
            json
          }
        }
      }
    }
  }
`

const ShopContent = () => {
  const { allContentfulProdejna: { edges } } = useStaticQuery(getContent)
  const { nazev, hlavniPopis } = edges[0].node
  return (
    <>
      <h3 className="no-line">{nazev}</h3>
      {documentToReactComponents(hlavniPopis.json)}
      <h3 className="no-line u-margin-top">Kde nás najdete?</h3>
      <Map />
    </>
  )
}

export default ShopContent
